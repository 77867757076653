import { Component, Vue } from 'vue-property-decorator';
import { SidemenuItem } from '@/components/sidemenu/Sidemenu';
import { userRoles } from '@/models/User';

@Component<Settings>({})
export default class Settings extends Vue {
  public $pageTitle = 'Instellingen';

  protected settingsItems: SidemenuItem[] = [
    {
      title: 'Gebruikersbeheer',
      link: '/instellingen/gebruikersbeheer',
      icon: {
        key: 'people',
      },
      visible: true,
    },
    {
      title: 'Simple Gebruikersbeheer',
      link: '/instellingen/simple-gebruikersbeheer',
      icon: {
        key: 'people',
      },
      visible: this.$store.state.Auth.hasRole(['admin', 'manager', 'helpdesk_tcmg']) && this.$store.state.isServiceOrganization,
    },
    {
      title: 'Deskundige Gebruikersbeheer',
      link: '/instellingen/deskundige-gebruikersbeheer',
      icon: {
        key: 'people',
      },
      visible: this.$store.state.Auth.hasRole(['admin', 'manager', 'helpdesk_tcmg']) && this.$store.state.isServiceOrganization,
    },
    {
      title: 'Deskundigenregister overzicht',
      link: '/instellingen/deskundige-register',
      icon: {
        key: 'dashboard',
      },
      visible: !! this.$store.state.Auth.has_expert_register && this.$store.state.Auth.hasRole(userRoles.ManagerRoles),
    },
    {
      title: 'Deskundigenregister dashboard',
      link: '/instellingen/deskundige-register-dashboard',
      icon: {
        key: 'dashboard',
      },
      visible: !! this.$store.state.Auth.has_expert_register && this.$store.state.Auth.hasRole(userRoles.ManagerRoles),
    },
    {
      title: 'Deskundigenregister aanvragen',
      link: '/instellingen/deskundige-register-submissions',
      icon: {
        key: 'dashboard',
      },
      visible: !! this.$store.state.Auth.has_expert_register && this.$store.state.Auth.hasRole(userRoles.ManagerRoles),
    },
    {
      title: 'Steekproef',
      link: '/instellingen/steekproef',
      icon: {
        key: 'dashboard',
      },
      visible: this.$store.state.Auth.hasRole(['admin']) && this.$store.state.isServiceOrganization,
    },
    {
      title: 'Alle Mededelingen',
      link: '/instellingen/mededelingen',
      icon: {
        key: 'add_alert',
      },
      visible: this.$store.state.Auth.hasRole(['admin']),
    },
    {
      title: 'Instellingen',
      link: '/instellingen/configuratie',
      icon: {
        key: 'settings',
      },
      visible: this.$store.state.Auth.hasRole(['admin']),
    },
  ];

  protected atabixItems: SidemenuItem[] = [
    {
      title: 'Rollen',
      link: '/instellingen/rollen',
      icon: {
        key: 'supervised_user_circle',
      },
      visible: this.$store.state.isBETA,
    },
    {
      title: 'Permissies',
      link: '/instellingen/permissies',
      icon: {
        key: 'supervised_user_circle',
      },
      visible: this.$store.state.isServiceOrganization,
    },
    {
      title: 'Vragenlijst',
      link: '/instellingen/questions',
      icon: {
        key: 'assignment',
      },
      visible: this.$store.state.isBETA && this.$store.state.isServiceOrganization,
    },
    {
      title: 'Standaardteksten',
      link: '/instellingen/default-answers',
      icon: {
        key: 'text_fields',
      },
      visible: this.$store.state.isServiceOrganization,
    },
  ];

  protected mounted() {
    this.emitBreadcrumb();
  }

  protected emitBreadcrumb() {
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [
          { name: 'Instellingen' },
        ],
      });
  }
}
